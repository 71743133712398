import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Paper,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Snackbar
} from '@mui/material';
import Confetti from 'react-confetti';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

// Update page title
document.title = "Maths.mom";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCN1qia8mSSV9jXU-fI73C3hQ-pRaevGrw",
  authDomain: "mathquest-d7b3d.firebaseapp.com",
  projectId: "mathquest-d7b3d",
  storageBucket: "mathquest-d7b3d.appspot.com",
  messagingSenderId: "318031520919",
  appId: "1:318031520919:web:0d454c2632c1c34e3af5d2",
  measurementId: "G-4TZ0VZC5PK"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const db = firebase.firestore();

const emojis = ['😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂', '🙂', '🙃'];

const MathQuest = () => {
  const [gameState, setGameState] = useState('start');
  const [childName, setChildName] = useState(localStorage.getItem('childName') || '');
  const [age, setAge] = useState('');
  const [level, setLevel] = useState(1);
  const [score, setScore] = useState(0);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState('');
  const [timeLeft, setTimeLeft] = useState(30);
  const [badges, setBadges] = useState([]);
  const [problemType, setProblemType] = useState('all');
  const [highScores, setHighScores] = useState([]);
  const [showAbout, setShowAbout] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [consecutiveFailures, setConsecutiveFailures] = useState(0);
  const [complexity, setComplexity] = useState(1);
  const [initialTime, setInitialTime] = useState(30);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    loadHighScores();
  }, []);

  const loadHighScores = async () => {
    const scoresSnapshot = await db.collection('highScores').orderBy('score', 'desc').limit(10).get();
    const scores = scoresSnapshot.docs.map(doc => doc.data());
    setHighScores(scores);
  };

  const saveHighScores = async (newScores) => {
    const batch = db.batch();
    newScores.forEach(score => {
      const scoreRef = db.collection('highScores').doc(score.name);
      batch.set(scoreRef, score, { merge: true });
    });
    await batch.commit();
    loadHighScores();
  };

  const generateQuestion = () => {
    let operation;
    if (problemType === 'all') {
      const operations = ['+', '-', '*', '/'];
      operation = operations[Math.floor(Math.random() * operations.length)];
    } else {
      operation = problemType;
    }

    let num1, num2;
    let maxNum = 5 + (complexity * 2);

    switch (operation) {
      case '+':
        num1 = Math.floor(Math.random() * maxNum) + 1;
        num2 = Math.floor(Math.random() * maxNum) + 1;
        break;
      case '-':
        num1 = Math.floor(Math.random() * maxNum) + 5;
        num2 = Math.floor(Math.random() * num1) + 1;
        break;
      case '*':
        num1 = Math.floor(Math.random() * (maxNum / 2)) + 1;
        num2 = Math.floor(Math.random() * (maxNum / 2)) + 1;
        break;
      case '/':
        num2 = Math.floor(Math.random() * Math.max(1, Math.floor(maxNum / 4))) + 1;
        num1 = num2 * (Math.floor(Math.random() * (maxNum / 4)) + 1);
        break;
    }

    const newQuestion = `${num1} ${operation} ${num2}`;
    setQuestion(newQuestion);
    setAnswer(eval(newQuestion).toFixed(2));
    setInitialTime(timeLeft);
  };

  useEffect(() => {
    if (gameState === 'playing') {
      generateQuestion();
      inputRef.current?.focus();
    }
  }, [level, gameState, problemType, complexity]);

  useEffect(() => {
    if (timeLeft > 0 && gameState === 'playing') {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0 && gameState === 'playing') {
      endGame();
    }
  }, [timeLeft, gameState]);

  const checkAnswer = () => {
    setFeedback(emojis[Math.floor(Math.random() * emojis.length)]);
    if (parseFloat(userAnswer).toFixed(2) === answer) {
      const timeBonus = initialTime - timeLeft;
      const points = 10 + timeBonus;
      setScore(score + points);
      setFeedback(`Correct! Great job! +${points} points! ${emojis[Math.floor(Math.random() * emojis.length)]}`);
      setConsecutiveFailures(0);
      if (score + points >= level * 50) {
        levelUp();
      } else {
        increaseComplexity();
      }
    } else {
      setFeedback(`Oops! The correct answer was ${answer}. Try again! ${emojis[Math.floor(Math.random() * emojis.length)]}`);
      setConsecutiveFailures(consecutiveFailures + 1);
      if (consecutiveFailures >= 1) {
        decreaseComplexity();
      }
    }
    setUserAnswer('');
    setTimeLeft(30);
    generateQuestion();
    inputRef.current?.focus();
  };

  const increaseComplexity = () => {
    setComplexity(prevComplexity => Math.min(prevComplexity + 1, 10));
  };

  const decreaseComplexity = () => {
    setComplexity(prevComplexity => Math.max(prevComplexity - 1, 1));
  };

  const levelUp = () => {
    setLevel(level + 1);
    setBadges([...badges, `Level ${level} Master`]);
    setFeedback(`Congratulations! You've reached Level ${level + 1}!`);
    setShowConfetti(true);
    increaseComplexity();
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000);
  };

  const endGame = async () => {
    setGameState('gameOver');
    setFeedback(`Game Over! Your final score is ${score}. Great effort, ${childName}!`);

    const newScores = [...highScores, { name: childName, score, level, date: new Date().toLocaleDateString() }];
    const uniqueScores = newScores.reduce((acc, curr) => {
      const existing = acc.find(item => item.name === curr.name);
      if (!existing || existing.score < curr.score) {
        acc = acc.filter(item => item.name !== curr.name);
        acc.push(curr);
      }
      return acc;
    }, []);
    const sortedScores = uniqueScores.sort((a, b) => b.score - a.score).slice(0, 10);
    await saveHighScores(sortedScores);
    setShowSnackbar(true);
  };

  const startGame = () => {
    setGameState('playing');
    setLevel(1);
    setScore(0);
    setBadges([]);
    setTimeLeft(30);
    setComplexity(ageComplexity(age));
    setConsecutiveFailures(0);
    generateQuestion();
  };

  const quitGame = () => {
    endGame();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      checkAnswer();
    }
  };

  const printCertificate = () => {
    const smiley = level > 5 ? '😊' : level > 3 ? '😃' : '🙂';
    const certificateContent = `
      <html>
        <body style="border: 5px solid black; padding: 20px; font-family: Arial, sans-serif;">
          <h1 style="text-align: center;">Certificate of Achievement</h1>
          <p style="text-align: center;">This certifies that</p>
          <h2 style="text-align: center;">${childName}</h2>
          <p style="text-align: center;">has successfully completed MathQuest: Adventure in Numberland</p>
          <p style="text-align: center;">reaching Level ${level} with a score of ${score}</p>
          <p style="text-align: center;">Date: ${new Date().toLocaleDateString()}</p>
          <p style="text-align: center; font-size: 50px;">${smiley}</p>
        </body>
      </html>
    `;
    const printWindow = window.open('', '_blank');
    printWindow.document.write(certificateContent);
    printWindow.document.close();
    printWindow.print();
  };

  const shareGame = () => {
    const shareMessage = `Challenge yourself with Maths.mom! Can you beat my score? Play now at https://mathquest-d7b3d.web.app/ and let's see who the math champion is!`;
    if (navigator.share) {
      navigator.share({
        title: 'Maths.mom Challenge',
        text: shareMessage,
        url: 'https://mathquest-d7b3d.web.app/',
      });
    } else {
      alert('Share this message:\n' + shareMessage);
    }
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const validateFields = async () => {
    if (childName.trim() === '' || age.trim() === '' || problemType.trim() === '') {
      alert('Please fill all the fields to start the game.');
      return false;
    }
    localStorage.setItem('childName', childName);
    return true;
  };

  const ageComplexity = (age) => {
    switch (age) {
      case '5-7':
        return 1;
      case '8-10':
        return 2;
      case '11-13':
        return 3;
      case '14-18':
        return 4;
      case '19-25':
        return 5;
      case '26-30':
        return 6;
      case '31-40':
        return 7;
      case '41-50':
        return 8;
      case '51+':
        return 9;
      default:
        return 1;
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" p={4} style={{ background: 'linear-gradient(to right, #FFDEE9, #B5FFFC)' }}>
      {showConfetti && <Confetti />}
      <Card style={{ maxWidth: '500px', width: '100%', marginBottom: '20px' }}>
        <CardHeader>
          <Typography variant="h4" component="div" align="center" style={{ color: '#333', fontFamily: 'Comic Sans MS' }}>Maths.mom: Adventure in Numberland</Typography>
        </CardHeader>
        <CardContent>
          {gameState === 'start' && (
            <>
              <Typography variant="h6" align="center" style={{ marginBottom: '20px', fontFamily: 'Comic Sans MS' }}>Welcome to Maths.mom!</Typography>
              <TextField
                fullWidth
                value={childName}
                onChange={(e) => setChildName(e.target.value)}
                label="Enter your name"
                variant="outlined"
                style={{ marginBottom: '20px', backgroundColor: '#fff', borderRadius: '8px' }}
                required
              />
              <Select
                value={age}
                onChange={(e) => setAge(e.target.value)}
                fullWidth
                variant="outlined"
                style={{ marginBottom: '20px', backgroundColor: '#fff', borderRadius: '8px' }}
                required
              >
                <MenuItem value="5-7">Age 5-7</MenuItem>
                <MenuItem value="8-10">Age 8-10</MenuItem>
                <MenuItem value="11-13">Age 11-13</MenuItem>
                <MenuItem value="14-18">Age 14-18</MenuItem>
                <MenuItem value="19-25">Age 19-25</MenuItem>
                <MenuItem value="26-30">Age 26-30</MenuItem>
                <MenuItem value="31-40">Age 31-40</MenuItem>
                <MenuItem value="41-50">Age 41-50</MenuItem>
                <MenuItem value="51+">Age 51+</MenuItem>
              </Select>
              <Select
                value={problemType}
                onChange={(e) => setProblemType(e.target.value)}
                fullWidth
                variant="outlined"
                style={{ marginBottom: '20px', backgroundColor: '#fff', borderRadius: '8px' }}
                required
              >
                <MenuItem value="all">All Operations</MenuItem>
                <MenuItem value="+">Addition</MenuItem>
                <MenuItem value="-">Subtraction</MenuItem>
                <MenuItem value="*">Multiplication</MenuItem>
                <MenuItem value="/">Division</MenuItem>
              </Select>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={async () => {
                  if (await validateFields()) startGame();
                }}
                style={{ backgroundColor: '#FF69B4', color: '#fff' }}
              >
                Start Game
              </Button>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={shareGame}
                style={{ backgroundColor: '#32CD32', color: '#fff', marginTop: '20px' }}
              >
                Share Game
              </Button>
            </>
          )}
          {gameState === 'playing' && (
            <>
              <Box mb={4}>
                <Typography variant="h6">Level: {level}</Typography>
                <Typography variant="h6">Score: {score}</Typography>
                <LinearProgress variant="determinate" value={(timeLeft / 30) * 100} style={{ marginTop: '10px', height: '10px', borderRadius: '5px' }} />
                <Typography variant="body2" align="right">Time left: {timeLeft}s</Typography>
              </Box>
              <Box mb={4}>
                <Typography variant="h4" align="center" style={{ fontFamily: 'Comic Sans MS', color: '#FF6347' }}>{question}</Typography>
              </Box>
              <TextField
                fullWidth
                type="number"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                onKeyPress={handleKeyPress}
                label="Enter your answer"
                variant="outlined"
                style={{ marginBottom: '20px', backgroundColor: '#fff', borderRadius: '8px' }}
                inputRef={inputRef}
              />
              <Button variant="contained" color="primary" fullWidth onClick={checkAnswer} style={{ backgroundColor: '#32CD32', color: '#fff', marginBottom: '20px' }}>
                Submit Answer
              </Button>
              <Typography variant="body1" align="center" style={{ color: '#FF4500', fontFamily: 'Comic Sans MS' }}>{feedback}</Typography>
              <Button variant="contained" color="secondary" fullWidth onClick={quitGame} style={{ backgroundColor: '#FF4500', color: '#fff', marginTop: '20px' }}>
                Quit Game
              </Button>
            </>
          )}
          {gameState === 'gameOver' && (
            <>
              <Typography variant="h4" align="center" className="mb-4" style={{ color: '#FF4500', fontFamily: 'Comic Sans MS', marginBottom: '20px' }}>{feedback}</Typography>
              <Button variant="contained" color="primary" fullWidth onClick={() => setGameState('start')} style={{ backgroundColor: '#FF69B4', color: '#fff', marginBottom: '20px' }}>
                Start New Game
              </Button>
              <Button variant="contained" color="secondary" fullWidth onClick={printCertificate} style={{ backgroundColor: '#32CD32', color: '#fff', marginBottom: '20px' }}>
                Print Certificate
              </Button>
            </>
          )}
        </CardContent>
      </Card>

      <Paper elevation={3} style={{ maxWidth: '500px', width: '100%', padding: '20px', borderRadius: '10px', backgroundColor: '#FFF8DC' }}>
        <Typography variant="h6" align="center" style={{ fontFamily: 'Comic Sans MS', color: '#333' }}>High Scores</Typography>
        <List>
          {highScores.map((highScore, index) => (
            <ListItem key={index} style={{ backgroundColor: index % 2 === 0 ? '#FFDAB9' : '#FFEFD5', borderRadius: '5px', marginBottom: '10px' }}>
              <ListItemText primary={`${index + 1}. ${highScore.name}`} secondary={`Score: ${highScore.score} | Level: ${highScore.level} | Date: ${highScore.date}`} />
            </ListItem>
          ))}
        </List>
      </Paper>

      <Dialog open={showAbout} onClose={() => setShowAbout(false)}>
        <DialogTitle>About Maths.mom</DialogTitle>
        <DialogContent>
          <Typography>Maths.mom: Adventure in Numberland was created by Daksh Solapurkar, who is 10 years old.</Typography>
          <Typography>For inquiries, please contact: dakshsolapurkar@gmail.com</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAbout(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Button variant="outlined" onClick={() => setShowAbout(true)} style={{ marginTop: '20px', color: '#333', borderColor: '#333' }}>
        About Maths.mom
      </Button>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="High scores updated!"
      />
    </Box>
  );
};

export default MathQuest;
